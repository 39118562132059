import * as React from "react";
import {Helmet} from "react-helmet";
import PageLayout from "../PageLayout";
import { Gallery } from "../components/Gallery";
import img1 from "../images/products/1.jpg";
import img2 from "../images/products/2.jpg";
import img3 from "../images/products/3.jpg";
import img4 from "../images/products/4.jpg";
import img5 from "../images/products/5.jpg";
import SectionLayout from "../SectionLayout";
import HowWeCanHelp from "../components/HowWeCanHelp";

const images = [
    {
        original: img1,
    },
    {
        original: img2,
    },
    {
        original: img3,
    },
    {
        original: img4,
    },
    {
        original: img5,
    },
];
const Products = () => {
    return (
        <PageLayout
            noButton
            title={"Our Products"}
            navSize={"h-80"}
            titleStyles={"xl:mt-12 lg:mt-16 md:mt-12 sm:mt-20 mt-16"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Products We Use
                </title>
                <meta
                    name="description"
                    content="Generator control systems and field transfer systems for fuel, water and power. Built to withstand the tough Territory conditions. Automation systems available."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                    <p className={"pt-12 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        At E-P-G Solutions, we use only the highest quality products to
                        design, construct and install reliable infrastructure for our clients.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        Our products include a range of
                        <span className={"font-bold"}> generator control systems </span>
                        and <span className={"font-bold"}> field transfer systems </span>that
                        can provide reliable supply of fuel, water or power where it’s needed.
                        All our systems have automation capability and can alert staff and
                        community members to issues with supply and operation.
                    </p>
                    <p className={"my-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        Whether you need to regulate fuel supply, water supply or power, we
                        can set you up with a tailored system for your project.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        The <span className={"font-bold"}> DT240 </span>
                        and <span className={"font-bold"}> DT240e </span>are our own designs
                        and are a 240V control system. This system regulates the pumps that
                        transfer fuel from the bulk tanks to the day tank. If the duty
                        selected pump fails, the secondary pump is automatically started, and
                        an alarm and strobe light alert are set off to advise staff on-site of
                        the fault. This can also be incorporated into the station SCADA which
                        allows for remote monitoring.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        The <span className={"font-bold"}> FTS12 </span>
                        is a solar powered system that charges the crank batteries for the
                        Hatz diesel transfer pumps. This system was originally designed to
                        transfer fuel from a barge landing bulk tanks to a power station
                        without the need to double-handle the fuel.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        The <span className={"font-bold"}> ATS230 </span> &
                        <span className={"font-bold"}> 415 </span>
                        Systems are designed to switch between the regular utility power and
                        the back-up generation system automatically with minimal interruption
                        to essential services.
                    </p>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We have the expertise to design a system specifically for your
                        project. Our systems are reliable and built for the Territory
                        conditions.
                    </p>
                    <div className={"py-5 lg:pt-10"}>
                        <Gallery images={images} />
                    </div>
            </SectionLayout>
            <HowWeCanHelp/>
        </PageLayout>
    );
};

export default Products
